<template>
	<NuxtLink class="button-cosmic-boxes" to="/cosmic-boxes/">
		<NuxtImg
			src="/nuxt-img/cosmic-boxes/bg-box.png"
			format="avif"
			width="82"
			height="82"
			loading="lazy"
			alt="cosmic boxes"
			class="bg"
		/>
		<NuxtImg
			src="/nuxt-img/cosmic-boxes/menu-box.png"
			format="avif"
			width="66"
			height="56"
			loading="lazy"
			alt="cosmic boxes"
			class="box"
		/>
	</NuxtLink>
</template>

<style scoped lang="scss">
.button-cosmic-boxes {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	transform: translateY(16px);
	width: 54px;
	height: 54px;
	position: relative;
}

.box {
	position: relative;
	z-index: 1;
	width: 44px;
	height: 37px;
}

.bg {
	position: absolute;
	left: calc(50% - 27px);
	top: calc(50% - 27px);
	width: 54px;
	height: 54px;
	animation: blink 10s linear infinite alternate;
}

@keyframes blink {
	0% {
		transform: scale(0.9) rotate(0deg);
	}
	25% {
		transform: scale(1.1) rotate(40deg);
	}
	50% {
		transform: scale(1) rotate(100deg);
	}
	75% {
		transform: scale(1.2) rotate(120deg);
	}
	100% {
		transform: scale(0.7) rotate(150deg);
	}
}
</style>
